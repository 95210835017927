exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acknowledgements-js": () => import("./../../../src/pages/acknowledgements.js" /* webpackChunkName: "component---src-pages-acknowledgements-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform-js": () => import("./../../../src/pages/news/2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform.js" /* webpackChunkName: "component---src-pages-news-2024-04-15-launch-technology-preview-1-sprucely-insights-dashboards-platform-js" */),
  "component---src-pages-news-2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io-js": () => import("./../../../src/pages/news/2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-07-01-launch-microsoft-powerpoint-addin-for-interactive-dashboards-with-sprucely-io-js" */),
  "component---src-pages-news-2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations-js": () => import("./../../../src/pages/news/2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations.js" /* webpackChunkName: "component---src-pages-news-2024-07-16-how-to-add-interactive-dashboards-and-multi-dimensional-charts-with-crossfiltering-in-powerpoint-ppt-presentations-js" */),
  "component---src-pages-news-2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io-js": () => import("./../../../src/pages/news/2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-09-18-launch-google-docs-and-slides-addon-for-interactive-dashboards-with-sprucely-io-js" */),
  "component---src-pages-news-2024-10-08-color-themes-support-for-sprucely-io-js": () => import("./../../../src/pages/news/2024-10-08-color-themes-support-for-sprucely-io.js" /* webpackChunkName: "component---src-pages-news-2024-10-08-color-themes-support-for-sprucely-io-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-connectors-js": () => import("./../../../src/pages/resources/connectors.js" /* webpackChunkName: "component---src-pages-resources-connectors-js" */),
  "component---src-pages-resources-examples-index-js": () => import("./../../../src/pages/resources/examples/index.js" /* webpackChunkName: "component---src-pages-resources-examples-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-integration-index-js": () => import("./../../../src/pages/resources/integration/index.js" /* webpackChunkName: "component---src-pages-resources-integration-index-js" */),
  "component---src-pages-resources-rest-api-js": () => import("./../../../src/pages/resources/rest-api.js" /* webpackChunkName: "component---src-pages-resources-rest-api-js" */),
  "component---src-pages-resources-services-js": () => import("./../../../src/pages/resources/services.js" /* webpackChunkName: "component---src-pages-resources-services-js" */),
  "component---src-pages-resources-support-js": () => import("./../../../src/pages/resources/support.js" /* webpackChunkName: "component---src-pages-resources-support-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service/[...].js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-terms-service-js": () => import("./../../../src/pages/terms-service.js" /* webpackChunkName: "component---src-pages-terms-service-js" */)
}

